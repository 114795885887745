import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";

export default function VideoPlayer(props) {
  const [showThumb, setShowThumb] = useState(true);
  const [videoPlaying, setVideoPlaying] = useState(false);

  return (
    <ReactPlayer
      url={props.url}
      playing={videoPlaying}
      height={"100%"}
      width={"100%"}
      controls={props.controls}
      // light={true}
      config={{
        youtube: {
          playerVars: {
            showinfo: 0,
            modestbranding: 1,
            rel: 0,
            autoplay: 1,
            controls:0
          },
        },
      }}
    />
  );
}
