export default function EmbeddedSpotify({ src }) {
    return (
      <div style={{ maxWidth: "500px", maxHeight: "40%" }} className="mx:auto">
        <iframe
          id="embediframe"
          src={src}
          width="100%"
          height="100%"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    );
  }