import TypeIt from "typeit-react";

export default function Tagline() {
    return (
      <TypeIt
        getBeforeInit={(instance) => {
          instance
            .type("any")
            .pause(350)
            .type("thing")
            .pause(850)
            .type(" really...")
            .pause(1200)
            .delete(18)
            .type("by me");
          return instance;
        }}
      />
    );
  }