import "./App.css";
import { FastAverageColor } from "fast-average-color";
import VideoPlayer from "./components/VideoPlayer";
import Gallery from "./components/Gallery";
import EmbeddedSpotify from "./components/EmbeddedSpotify";
import FullScreenImage from "./components/FullScreenImage";
import Slide from "./components/Slide";
import TagLineSlide from "./slides/TagLineSlide";
import AudioPlayerSlide from "./slides/AudioPlayerSlide";

function App() {
  return (
    <div className="App">
      <Snap>
        {/* <AudioPlayerSlide /> */}
        <TagLineSlide />

        <Gallery>
          <FullScreenImage src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_18-2400.jpg" />
          <FullScreenImage src="https://s3.amazonaws.com/standardsmanualimages/gallery/original_002-2500.jpg" />
          <FullScreenImage src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_6-2400.jpg" />
          <FullScreenImage src="https://s3.amazonaws.com/standardsmanualimages/product-nycta/NYCTA_GSM_DETAIL_16-2400.jpg" />
        </Gallery>
        <VideoPlayer url={"https://www.youtube.com/watch?v=L9VBpbnXhWk"} />
        <Slide>
          <FullScreenImage src="images/maxresdefault.jpg" />
        </Slide>
        <Slide>
          <FullScreenImage src="images/Timberland2.jpg" />
        </Slide>
        <Slide>
          <FullScreenImage src="images/gandtengland.jpg" />
        </Slide>
        <Slide>
          <div
            className="w-screen h-screen flex items-center justify-center"
            style={{
              background:
                "radial-gradient(rgba(93,188,231,1) 0%, rgba(93,188,231,1) 16%, rgba(63,126,155,1) 100%)",
              backgroundcolor: "#3f7e9b",
            }}
          >
            <EmbeddedSpotify src="https://open.spotify.com/embed/track/5MWA0KkMh18hC2BkcCs4me" />
          </div>
        </Slide>
        <Slide>
          <FullScreenImage src="images/mix3_1920x.jpg" />
        </Slide>

        <Slide>
          <div
            className="w-screen h-screen flex items-center justify-center appearance-none"
            style={{
              background:
                "radial-gradient(circle, rgba(41,99,133,1) 0%, rgba(6,19,26,1) 100%)",
              backgroundcolor: "rgba(41,99,133,1)",
            }}
          >
            <div
              className="mx:auto spotifyplaylist"
              style={{
                maxHeight: "60%",
                height: "500px",
              }}
            >
              <iframe
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/playlist/2YK29czdRtZsXiE9cKMT69?utm_source=generator"
                width="100%"
                height="100%"
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen;"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </Slide>
        <Slide>
          <VideoPlayer
            url={"https://www.youtube.com/watch?v=H6lWMsMjTgI"}
            controls
          />
        </Slide>
        <Slide>
          <VideoPlayer
            url={
              "https://www.youtube.com/playlist?list=PLdRxzzDAn5LrdPwYI4-2QSJn05t92KfgN"
            }
          />
        </Slide>
      </Snap>
    </div>
  );
}

export default App;

function Snap(props) {
  return (
    <div className="snap-y snap-mandatory h-screen w-screen mx:auto overflow-x-hidden">
      {props.children.map((item) => (
        <div className="snap-center w-screen h-screen flex items-center justify-center">
          {item}
        </div>
      ))}
    </div>
  );
}
